.Confirmation-dialog-div {
    z-index: 10003 !important;
}

.Confirmation-dialog-div .Confirmation-dialog-div {
    z-index: 10003 !important;
}

.css-hgttjy-MuiPaper-root-MuiDialog-paper {
    z-index: 10003 !important;
}
